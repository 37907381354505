import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import mainService from '../../service/index';
import Fab from '@material-ui/core/Fab';
import RateReviewRoundedIcon from '@material-ui/icons/RateReviewRounded';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RemoveIcon from '@material-ui/icons/Remove';
import SendIcon from '@material-ui/icons/Send';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { emojify } from 'react-emojione';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import styleClass from './styleClass';
import Snackbar from '@material-ui/core/Snackbar';


export default function FeedBack(props: any) {
  const classes = styleClass();
  const [feedBack, setFeedBack] = useState<boolean>(false);
  const [collapse, setCollapsed] = useState<boolean>(false);
  const [lblFab, setLblFab] = useState<string>('');
  const [emojiValue, setEmojiValue] = useState<string>('');
  const [responseValue, setResponseValue] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [descriptionInvalid, setDescriptionInvalid] = useState<boolean>(false);
  const [descriptionHelperText, setDescriptionHelperText] = useState<string>('');




  const timer = React.useRef<number>();

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function handelFabMouseEnter(e: any) {
    console.log('MouseOver', e);
    if (e.type === "mouseenter") {
      setLblFab('Rate this topic');
    } else {
      setLblFab('');
    }
  }
  function valuetext(value: number) {
    if (value <= 10) {
      setEmojiValue(':(');
      setResponseValue('Confused');
      return 'Invalid';
    } else if (value <= 20) {
      setEmojiValue(':|');
      setResponseValue('Bad');
      return 'Bad ';
    } else {
      setEmojiValue(':)');
      setResponseValue('Good');
      return 'Good';
    }
    // return `${Math.round(coefficient)}e^${exponent}`;
  }
  function handleDescriptionChange(event: any) {
    setDescription(event.target.value);
    if (event.target.value.length === 0) {
      setDescriptionInvalid(true)
      setDescriptionHelperText("Feedback is required");
    } else {
      setDescriptionInvalid(false)
      setDescriptionHelperText("");
    }
  }
  const sendFeedBack = () => {
    if (description.length === 0) {
      setDescriptionInvalid(true)
      setDescriptionHelperText("Feedback is required");
      return false;
    } else {
      setDescriptionInvalid(false)
      setDescriptionHelperText("");
    }


    if (!loading) {
      setSuccess(false);
      setLoading(true);
      const data = {
        feedbacktype: responseValue,
        comments: description,
        lessonid: props.match.params.topicId,
        ver: '1'
      }
      console.log("test", props);
      mainService.postData(props, data, "feedback/save").then(
        (response: any) => {
          setFeedBack(false);
          setSuccess(true);
          setLoading(false);
          setLblFab('');
        });
    }
  }
  const options = {
    style: {
      height: 100,
      margin: 'auto',
    },
    // this click handler will be set on every emoji
  };
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
  const handleSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccess(false);
  };
  return (
    <>
      {!feedBack ? (
        <Fab variant="extended" className={classes.fab} color={'secondary'} onClick={() => { setFeedBack(true) }} onMouseEnter={handelFabMouseEnter} onMouseLeave={handelFabMouseEnter}>
          <RateReviewRoundedIcon className={classes.extendedIcon} />
          {lblFab}

        </Fab>
      ) : ""}

      <Snackbar
        open={success}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        className={classes.snackbar}
      >
        <Alert severity="success">
          Yor feedback submitted.
    </Alert>
      </Snackbar>
      <Collapse in={feedBack}>
        <Paper elevation={4} className={classes.paper}>
          <form className={classes.form} noValidate autoComplete="off">

            <div className={classes.emojiContainer}>


              {emojify(emojiValue, options)}


            </div>



            <Slider
              defaultValue={30}
              aria-labelledby="discrete-slider"
              getAriaValueText={valuetext}
              valueLabelFormat={valuetext}
              valueLabelDisplay="off"
              step={10}
              marks
              min={10}
              max={30}
            />
            <div className={classes.descriptionContainer}>
              <TextField
                id="standard-multiline-static"
                label="Write your feedback"
                multiline
                rows={4}
                defaultValue=""
                className={classes.descriptionText}
                onChange={handleDescriptionChange}
                error={descriptionInvalid}
                helperText={descriptionHelperText}
              />
            </div>
          </form>
          <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>

              <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={() => { setFeedBack(false) }}>
                <RemoveIcon />
              </IconButton>


              <div className={classes.wrapperSendBtn}>
                <Fab
                  aria-label="save"
                  color="primary"
                  className={buttonClassname}
                  onClick={sendFeedBack}
                >
                  {success ? <SaveIcon /> : <SendIcon />}
                </Fab>
                {loading && <CircularProgress size={68} className={classes.fabProgress} />}
              </div>
              <div className={classes.grow} />

            </Toolbar>
          </AppBar>
        </Paper>

      </Collapse>
    </>
  )
}