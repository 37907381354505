const mainService = {
  getAPiUrl: function (props: any) {
    let url:string = "";
    // url += props.match.params.ssl === 1 ? "https://" : "http://";
    url += decodeURIComponent(props.match.params.domain)+"api/";

    return url;
  },
  getJsonData: function (props: any, url: any) {
    return new Promise((resolve, reject) => {

      url = this.getAPiUrl(props) + url;
      console.log("Route url", url)
      fetch(url, {
        method: 'GET',
        headers: {
          authorization: 'Basic '+ props.match.params.apiKey
        }
        
      })
        .then((res) => res.json())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  },

  postData: function (props: any,data:any, url: any) {

    return new Promise((resolve, reject) => {
      data['ver'] = 1;
      fetch(this.getAPiUrl(props) + url+"?ver=1", {
        method: 'POST',

        body: JSON.stringify(data),
        headers: {
          authorization: 'Basic ' + props.match.params.apiKey,
        }
      })
        .then((res) => res.json())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }
}
export default mainService;