import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import { Link } from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import MenuIcon from '@material-ui/icons/Menu';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MovieIcon from '@material-ui/icons/Movie';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import RateReviewRoundedIcon from '@material-ui/icons/RateReviewRounded';

import styleClass from './styleClass';
import mainService from '../service/index';
import { TopicData, LessonData } from './LessonDataInterface'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import PDFViewer from 'pdf-viewer-reactjs';
import CustomNavigation from '../components/pdf/navigation';
import VideoPlayer from '../components/video/player';
import HtmlText from '../components/textImage/text';
import Question from '../components/question/index';
import Box from '@material-ui/core/Box';
import PdfComp from '../components/pdf/index';
import FeedBack from '../components/feedback/index';
const PdfView = (props: any) => {
  const classes = styleClass();
  return (

    <Container className={classes.pdfContainer}>
      <PDFViewer
        document={{
          url: props.fileUrl,
        }}
        // scale={1}
        scaleStep={0.5}
        navbarOnTop
        css={classes.pdfViewer}
        canvasCss='customCanvas'
        navigation={CustomNavigation}
        protectContent={true}


      />
    </Container>
  )

}

export default function MiniDrawer(props: any) {
  const classes = styleClass();
  const theme = useTheme();
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [lessonName, setLessonName] = useState();
  const [listTopics, setListTopics] = useState<TopicData[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [topicType, setTopicType] = useState('');
  const [fileUrl, setFileUrl] = useState<string>('');
  const [topicName, setTopicName] = useState('');
  const [textHtml, setTextHtml] = useState('');
  const [jsonData, setJsonData] = useState<String[]>([]);
  const [lblFab, setLblFab] = useState<string>('');

  useEffect(() => {
    console.log('props', props);

    
    // Update the document title using the browser API
    mainService.getJsonData(props, "learn/listTopics/" + props.match.params.lessonId + "?ver=1").then(
      (response: any) => {
        setLessonName(response.data.lesson_name);
        setListTopics(response.data.topic_data);
        handleListItemClick(props.match.params.topicId);
      }
    );
  }, [reload]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (
    topicId: number,
  ) => {
   
    console.log(topicId);
    setSelectedIndex(topicId);


    mainService.getJsonData(props, "learn/details/" + topicId + "?ver=1").then(
      (response: any) => {
        setTopicType(response.data.data_type);
        setTextHtml(response.data.content_text);


        if (response.data.data_type === 'Youtube') {
          setFileUrl(response.data.content_Youtube);

        } else if (response.data.data_type === 'Vimeo') {
          setFileUrl(response.data.content_Vimeo);
        } else if (response.data.data_type === 'lesson_questions') {
          setJsonData(JSON.parse(response.data.json_data));
        } else {
          setFileUrl(response.data.mediaUrl);
        }
        // setFileUrl("https://arxiv.org/pdf/quant-ph/0410100.pdf");
        setTopicName(response.data.lesson_data_name);
        window.parent.postMessage({topicId: topicId,title: response.data.lesson_data_name}, '*');


      }
    );
  };
  const topicIcon = (type: string) => {
    switch (type) {
      case 'lesson_questions':
        return <QuestionAnswerIcon />;
      case 'audio':
        return <AudiotrackIcon />;
      default:
      case 'text':
        return <ImportContactsIcon />;
      case 'pdf_file':
        return <PictureAsPdfIcon />;
      case 'video':
        return <MovieIcon />;
      case 'Youtube':
        return <YouTubeIcon />;
      case 'Vimeo':
        return <MovieIcon />;
    }
  }

  function handelFabMouseEnter(e: any) {
    console.log('MouseOver', e);
    if (e.type === "mouseenter") {
      setLblFab('React');
    } else {
      setLblFab('');
    }
  }
  return (
    <div className={classes.root}>

      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {lessonName} \ {topicName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Box display="flex" p={1} className={classes.drawerTitleBox}>
          <Box p={1} flexGrow={1} >
            <span className={classes.drawerTitle}>Topics</span>
          </Box>
          <Box p={1} >
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </Box>
        </Box>


        <Divider />
        <List>

          {

            listTopics.map((text, index) => (

              <ListItem button key={text.lesson_data_id}
                selected={selectedIndex === text.lesson_data_id}
                onClick={(event) => handleListItemClick(text.lesson_data_id)}
              >
                <ListItemIcon>{topicIcon(text.data_type)} </ListItemIcon>
                <ListItemText primary={text.name} />
              </ListItem>
            ))
          }


        </List>

      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {
          topicType === 'audio' ?
            <Container>
              <AudioPlayer
                autoPlay
                src={fileUrl}
                onPlay={e => console.log("onPlay")}

              // other props here
              />
            </Container>
            : ""}
        {topicType === 'pdf_file' ? <PdfComp fileUrl={fileUrl} /> : ""}
        {topicType === 'video' ? <VideoPlayer html={textHtml} fileUrl={fileUrl} /> : ""}
        {topicType === 'Youtube' ? <VideoPlayer html={textHtml} fileUrl={fileUrl} /> : ""}
        {topicType === 'Vimeo' ? <VideoPlayer html={textHtml} fileUrl={fileUrl} /> : ""}
        {topicType === 'text' ? <HtmlText html={textHtml} /> : ""}
        {topicType === 'lesson_questions' ? <Question json={jsonData} /> : ""}


      </main>
      <FeedBack {...props} /> 
      
    </div>
  );
}
