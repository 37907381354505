import React from 'react';
import logo, { ReactComponent } from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation
} from "react-router-dom";

import Main from './main/index';
class App extends React.Component{
  render() {
    return (
      <Router>
      <Switch>

          {/* <Route  component={Main} ></Route> */}
          <Route exact path="/topic/:topicId/:lessonId/:apiKey/:domain/:ssl" component={Main} ></Route>
          
      </Switch>
      </Router>
    );  
  }
}

export default App;


//Todos

// Background hash other  page
//bread crumb  Home/ Learn

