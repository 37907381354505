import React from 'react';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {

      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    appBar: {
      width: 'auto',
      position: 'absolute',
      left: 0,
      top: 'auto',
      bottom: 0,
    },
    paper: {
    
      width: 300,
      height: 400,
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    form: {
      padding:20,
    },
        grow: {
      flexGrow: 1,
    },
    fabButton: {
      position: 'absolute',
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: '0 auto',
    },
    emojiContainer: {
      marginLeft: 85,
      
    },
    descriptionContainer: {
      marginTop:15,
    },
    descriptionText: {
      width: '100%'
    },
    wrapperSendBtn: {
      margin: theme.spacing(1),
      position: 'absolute',
      bottom: theme.spacing(0),
      right: theme.spacing(0),
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },

      
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    snackbar: {
      [theme.breakpoints.down('xs')]: {
        bottom: 10,
      },
      bottom: theme.spacing(10) + '!important',
    }
  }),
);
export default useStyles;