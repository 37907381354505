import React from 'react'
import ReactPlayer from 'react-player'
import Container from '@material-ui/core/Container';
import renderHTML from 'react-render-html';
import styleClass from './styleClass';

interface Props {
  fileUrl: string;
  html: string;
}
export default function VideoPlayer(props: Props) {

  return (
    <>
      <Container>
        <ReactPlayer url={props.fileUrl} width="100%" height="450px" controls={true} />
      </Container>

      <Container >
        {renderHTML(props.html)}
      </Container>
    </>
  )

}