import React from 'react'

import { Worker, Button, Icon, Position, SpecialZoomLevel, Tooltip, Viewer } from '@react-pdf-viewer/core';
// import { pageNavigationPlugin, RenderGoToNextPageProps, RenderGoToPreviousPageProps } from '@react-pdf-viewer/page-navigation';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import Container from '@material-ui/core/Container';
import styleClass from '../../main/styleClass';
import CustomNavigation from './navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
interface Props {
  fileUrl: string;
}




function PdfView(props: any) {
  const classes = styleClass();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (

      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <Viewer
        fileUrl={props.fileUrl}
         plugins={[
          // Register plugins
          defaultLayoutPluginInstance,
      ]}
        />
      </Worker>
  )

}

export default PdfView