import React from 'react'
import renderHTML from 'react-render-html';
import Container from '@material-ui/core/Container';




export default function question(props: any) {
  function Question(props:any) {
    return (
      <h2 className="question">{props.content}</h2>
    );
  }
  function AnswerOption(index:number,answerContent:string,answer:number) {
    console.log("Props", props);
    return (
      <li className="answerOption">
        <input
          type="radio"
          className="radioCustomButton"
          name="radioGroup"
          checked={props.answerType === props.answer}
          id={props.answerType}
          value={props.answerType}
          disabled={props.answer}
          onChange={props.onAnswerSelected}
        />
        <label className="radioCustomLabel" htmlFor={props.answerType}>
          {props.answerContent}
        </label>
      </li>
    );
  }
  interface TypeOption {
    option_text: string[];
    question: string;
  }
  
  function renderAnswerOptions(json:TypeOption) {
    console.log("test", json.option_text);
    let answerOptions:string[] = [];
    if (typeof json.option_text != 'undefined') {
       answerOptions = json.option_text;
    } 
    return (
      <>
        {
          Object.keys(answerOptions).map((index:any, i:any) => {
            console.log(answerOptions[index], i);
            
            AnswerOption(index, answerOptions[index], props.correct_answer);
        
      })
    }
        </>
    );
  }
  console.log("testing",props.json);
  return(

    <Container>
      <Question content={props.json.question} />
      {renderAnswerOptions(props.json)}
      
    </Container>
)
  
}
