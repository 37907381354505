import React from 'react'
import renderHTML from 'react-render-html';
import Container from '@material-ui/core/Container';



interface Props  {
  html: string;
}
export default function htmlText(props: Props) {

  return(

    <Container>
      {renderHTML(props.html)}

    </Container>
)
  
}
